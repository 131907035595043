import React from 'react';
import styled from 'react-emotion';
import SadBlock from '../assets/images/sad_block.svg';
import Footer from '../components/footer';
import Navigation from '../components/navigation';
import Layout from '../layouts';

const MainWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  height: 'calc(100vh)',
})

const ContentWrapper = styled('div')({
  display: 'flex',
  margin: '0 auto',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '0 40px',
  maxWidth: '1000px',
})

const Heading = styled('div')({
  fontSize: '20px',
  lineHeight: '28px',
  fontWeight: 600,
  marginTop: '40px',
})

const Paragraph = styled('div')({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#808080',
  marginTop: '10px',
  textAlign: 'center',
})

const NotFoundPage = () => (
  <Layout>
    <MainWrapper>
      <Navigation />
      <ContentWrapper>
        <img src={SadBlock} alt="Sad block" />
        <Heading>Oh no, we can't find this page.</Heading>
        <Paragraph>
          It's not you (probably), it's us. The page you're looking for doesn't
          exist.
        </Paragraph>
      </ContentWrapper>
      <Footer />
    </MainWrapper>
  </Layout>
)

export default NotFoundPage
